import React, { useState, useEffect } from 'react';
import { Container, Row , Image, Card, Col } from "react-bootstrap";
import BlasfemiaMobile from "../../assets/images/page_SomosBlasfemia/Blasfemia.png";
import Trazado744 from "../../assets/images/page_SomosBlasfemia/Trazado744.png";
import Trazado746 from "../../assets/images/page_SomosBlasfemia/Trazado746.png";
import VinoTintor from "../../assets/images/page_SomosBlasfemia/VinoTintor.png";
import Img733 from "../../assets/images/page_SomosBlasfemia/Image733.png";
import Img735 from "../../assets/images/page_SomosBlasfemia/Image735.png";
import VinoBlanco from "../../assets/images/page_Home/VinoBlanco.png";
import VinoRosado from "../../assets/images/page_Home/VinoRosado.png";
import Blasfemia from "../../assets/images/page_Home/Blasfemia.png";
import Img1 from "../../assets/images/page_SomosBlasfemia/img1.png";
import Img2 from "../../assets/images/page_SomosBlasfemia/img2.png";
import Img3 from "../../assets/images/page_SomosBlasfemia/img3.png";
import Img4 from "../../assets/images/page_SomosBlasfemia/img4.png";
import VinoTinto from "../../assets/images/page_Home/VinoTinto.png";
import Imggroup from "../../assets/images/page_Home/Grupo 760.png";
import Elipse39 from "../../assets/images/page_Home/Elipse 39.png";
import Elipse16 from "../../assets/images/page_Home/Elipse 16.png";
import ContainerStyles from "../styles/SomosBlasfemia.module.css";
import Elipse2 from "../../assets/images/page_Home/Elipse 2.png";
import Elipse3 from "../../assets/images/page_Home/Elipse 3.png";
import Elipse4 from "../../assets/images/page_Home/Elipse 4.png";
import Elipse5 from "../../assets/images/page_Home/Elipse 5.png";
import AliceCarousel from 'react-alice-carousel';
import Footer from "../components/FooterPage";
import Header from "../components/Header";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function SomosBlasfemia({ rawData }) {
  const data = rawData.map(e => e.node),
  [current, setCurrent] = useState(0),
  [items, setItems] = useState(data[0].images.map(c => <img src={c} className={ContainerStyles.imagecarouselsb} onDragStart={e => e.preventDefault()} alt="" />)),
  
  changeContent = i => {
    setCurrent(i);
    setItems(data[i].images.map(c => <img src={c} className={ContainerStyles.imagecarouselsb} onDragStart={e => e.preventDefault()} alt="" />));
  },
  
  [imageHover, setImageHover] = useState(false);

  let AOS;
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });


  return (
    <div className={ContainerStyles.bgpink}>
      <Image src={Img733} className={ContainerStyles.bgsb + " d-none d-md-block"} />
      <Image src={Img735} className={ContainerStyles.bgbtsb + " d-none d-md-block"}/>
      <Image src={Trazado746} className={ContainerStyles.bgsb + " d-block d-md-none"} />
      <Image src={Trazado744} className={ContainerStyles.bgbtsb +" d-block d-md-none"} />
      <Header activeItem='somosblasfemia' />
      <Image src={Elipse2} className={ContainerStyles.elipsesb2 + " d-none d-md-block"} />
      <Image src={Elipse3} className={ContainerStyles.elipsesb3 + " d-none d-md-block"} />
      <Image src={Elipse4} className={ContainerStyles.elipsesb4 + "d-none d-md-block"} />
      <Image src={Elipse5} className={ContainerStyles.elipsesb5} />
      <Image src={Elipse5} className={ContainerStyles.elipsesb51 + " d-none d-md-block"} />
      <Image src={VinoBlanco} onMouseEnter={() => setImageHover(true)} className={imageHover ? (ContainerStyles.vinoblancosbhover + " d-none d-md-block") : (ContainerStyles.vinoblancosb + " d-none d-md-block") } onMouseLeave={() => setImageHover(false)} />
      <Image src={VinoRosado} onMouseEnter={() => setImageHover(true)} className={imageHover ? (ContainerStyles.vinorosadosbhover + " d-none d-md-block") : (ContainerStyles.vinorosadosb + " d-none d-md-block") } onMouseLeave={() => setImageHover(false)} />
      <Image src={VinoTinto}  onMouseEnter={() => setImageHover(true)} className={imageHover ? (ContainerStyles.vinotintosbhover +" d-none d-md-block") : (ContainerStyles.vinotintosb +" d-none d-md-block") } onMouseLeave={() => setImageHover(false)}/>
      <Image src={VinoTintor} className={ContainerStyles.vinotintosb +" d-block d-md-none" }/>
      <Image src={Elipse2} className={ContainerStyles.elipsesb21 + " d-none d-md-block" } />
      <Image src={Elipse39} className={ContainerStyles.elipsesb39 + " d-none d-md-block"} />
      <Image src={Elipse3} className={ContainerStyles.elipsesb31}/>
      <Image src={Elipse3} className={ContainerStyles.elipsesb32}/>
      <Image src={Elipse16} className={ContainerStyles.elipsesb16}/>
      <Container fluid>
        <Row className={ContainerStyles.marginleftsb + " d-flex flex-column"}>
          <h1 className={ContainerStyles.firsttitlesb}>Hay costumbres <br/> que no tienen <br/> ningún  sentido.</h1>
          <p className={ContainerStyles.firstsubtitlesb + " d-none d-md-block"}>¿Por qué no podemos cortar los spaghettis? <br/> ¿Quién nos dijo que no podemos usar ojotas con medias? ¿Y si <br/> te copa tatuarte en un brazo la cara de tu vieja? <br/> <span className="font-weight-bold">La vida es una sola</span> , y tu viejatambién.</p>
          <p className={ContainerStyles.firstsubtitlesb + " d-block d-md-none"}>¿Por qué no podemos cortar los spaghettis?  ¿Quién nos dijo que no podemos usar ojotas con medias? ¿Y site copa tatuarte en un brazo la cara de tu vieja? <span className="font-weight-bold">La vida es una sola</span> , y tu vieja  también.</p>
          <p className={ContainerStyles.firstsubtitlesb + " d-none d-md-block"}>El mundo está lleno de Prejuicios. La gente está siendo <br/> juzgada por su género, orientación sexual, pensamiento <br/> político, religioso y muchas cosas más. Así como el mundo del <br/> vino también está lleno de dogmas y rituales super complejos.</p>
          <p className={ContainerStyles.firstsubtitlesb + " d-block d-md-none"}>El mundo está lleno de Prejuicios. La gente está  siendo  juzgada por su género, orientación sexual, pensamiento  político, religioso y muchas cosas   más. Así como el mundo del vino también está  lleno de dogmas y rituales super complejos.</p>
          <p className={ContainerStyles.secondtitlesbmarginbottomsb1}>Por eso lanzamos</p>
          <Image src={Blasfemia} alt="" className={ContainerStyles.blasfemiasb + " d-none d-md-block"} />
          <Image src={BlasfemiaMobile} alt="" className={ContainerStyles.blasfemiasb + " d-block d-md-none"} />
          <p className={ContainerStyles.firstsubtitlesbmargintopsb+ " d-none d-md-block"}>un vino que llego para cambiar los dogmas establecidos.</p>
          <p className={ContainerStyles.firstsubtitlesbmargintopsb + " d-block d-md-none"}>un vino que llego para cambiar los <br/> dogmas establecidos.</p>
        </Row>
      </Container>
      <Image src={Img3} className={ContainerStyles.imgsb3}  data-sal="fade-in" data-sal-delay="500" data-sal-easing="ease-in-out" />
      <Image src={Img2} className={ContainerStyles.imgsb2}  data-sal="fade-in" data-sal-delay="500" data-sal-easing="ease-in-out"/>
      <Image src={Img4} className={ContainerStyles.imgsb4 + " d-none d-md-block"}   />
      <Image src={Img1} className={ContainerStyles.imgsb1 + " d-none d-md-block"} data-sal="fade-in" data-sal-delay="500" data-sal-easing="ease-in-out"/>
      <Image src={Imggroup} className={ContainerStyles.imggroupsb} />
      <Container fluid>
        <Row className={ContainerStyles.marginsb1 + " d-flex flex-column align-content-end" }>
          <p className={ContainerStyles.secondsubtitlesb + " d-none d-md-block" }>Un vino en lata, joven y fácil de tomar con 3 varietales <br/> <span className="font-weight-bold"> Malbec Syrah en lata, Chenin Torrontés en lata y Rosado  <br/> en lata.</span> Sin rituales complejos ni excéntricas descripciones de  <br/> varietales inentendibles. De consumo individual y práctico para  <br/> llevar donde sea que la ocasión lo amerite. Cuando y como  <br/> quieras porque en la vida, no hay que saber de ciertas cosas <br/> para poder disfrutarlas. </p>
         <div className="d-flex "> <p className={ContainerStyles.secondsubtitlesb + "  d-md-none" }>Un vino en lata, joven y fácil de tomar con 3 varietales  <span className="font-weight-bold"> Malbec  Syrah en lata, Chenin Torrontés  en lata y Rosado en lata.</span> Sin rituales complejos ni excéntricas descripciones de  varietales inentendibles. De consumo  individual y práctico para llevar donde sea que la ocasión lo amerite. Cuando y como quieras porque en la vida, no hay que  saber de ciertas cosas para poder disfrutarlas. </p>
         </div>
        </Row>
        <Row className={ContainerStyles.marginsb2 + " d-flex flex-column align-content-start"}>
          <p className={ContainerStyles.secondsubtitlesb + " d-none d-md-block" }>Pragmático, espontáneo, independiente, con sentido del humor <br/> ácido e inteligente. Así es Blasfemia, en ciertos espacios puede <br/> resultar un tanto incómodo y estamos orgullosxs que así sea. Pero <br/> es una celebración del sano egoísmo, de la libertad. Un guiño a <br/> esas personas fieles a sí mismas y a nadie más. Que le escapan a <br/> las tradiciones y que se animan a esas convenciones que están <br/> mal vistas, pero nadie sabe explicar por qué, solo son prejuicios. </p>
          <p className={ContainerStyles.secondsubtitlesb1 + " d-block d-md-none" }>Pragmático, espontáneo, independiente, con <br/> sentido del humor ácido e inteligente. Así es <br/> Blasfemia, en ciertos espacios puede resultar un <br/> tanto incómodo y estamos orgullosxs que así <br/> sea. Pero es una celebración del sano egoísmo, <br/> de la libertad. Un guiño a esas personas fieles a <br/> sí mismas y a nadie más. Que le escapan a  las <br/> tradiciones y que se animan a esas <br/> convenciones que están mal vistas, pero nadie <br/> sabe explicar por qué, solo son prejuicios. </p>
          <h3 className={ContainerStyles.secondtitlesb + " mt-lg-5"}>Si pensás como nosotrxs</h3>
          <h2 className={ContainerStyles.bienvenidosb}>bienvenidx al mundo <br/> Blasfemia.</h2>
          <h3 className={ContainerStyles.secondtitlesb + " mt-lg-5"}>Y si no te gusta, unfollow.</h3>
        </Row>
      </Container>
      <Container fluid>
        <Row className="d-flex flex-column">
          <h1 className={ContainerStyles.fourtitlesb}>Esto es <br/> Blasfemia</h1>
          <p className={ContainerStyles.foursubtitlesb + " d-none d-md block"}>Filtrar por:</p>
        </Row>
        <Row className={ContainerStyles.marginsb3}>
          {data.map((e, i) => <button key={i} onClick={() => changeContent(i)} className={current === i ? ContainerStyles.buttonselectsb : ContainerStyles.buttonnoselectsb}>{e.button}</button>)}
        </Row>
        <Row className={ContainerStyles.marginsb4 + " py-0 py-lg-5"}>
          <Col xs={12} md={6} className={ContainerStyles.marginsb5}>
            <Card className="bg-transparent border-0">
              <h1 className={ContainerStyles.cardtitlesb}>{data[current].title}</h1>
              <p className={ContainerStyles.cardsubtitlesb + " d-none d-md-block"}>{data[current].text}</p>
              <p className={ContainerStyles.cardsubtitlesb + " d-block d-md-none"}>{data[current].text}</p>
            </Card>
          </Col>
          <Col xs={12} md={5} className={ContainerStyles.carouselcontainer}>
            <div className={ContainerStyles.carouselsb}>
                <AliceCarousel
                  mouseTracking
                  items={items}
                  infinite={true}
                  disableSlideInfo={true}
                  disableDotsControls={true}
                  autoPlayStrategy={'default'} // pausa el carousel durante hover
                  responsive={{ 0: { items: 2 }, 768: { items: 3 }, 1366: { items: 3 }}}
                />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}