import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import SomosBlasfemia from '../components/SomosBlasfemia';

export default function somosblasfemia() {
    return (
        <StaticQuery
            query={graphql`
            query getContent {
                allContent(sort: {fields: index, order: ASC}) {
                    edges {
                        node {
                            button
                            images
                            title
                            text
                            index
                        }
                    }
                }
            }`}
            render={({allContent}) => <SomosBlasfemia rawData={allContent.edges} />}
        />
    );
}